<!--

      This wizard opens right after the registration and asks the user for
      important settings.

 -->

<template>
  <Page hidelargeheader="true" hideheader="true" :noPaddingTop="true" :containsSlides="true" :noPaddingBottom="true" :scroll="false">
    <Slides
      :redirect="this.redirect"
      :showBack="this.$store.state.onboarded"
      @clicked="this.clickedNext"
      :analyticsEvent="'onboarding'"
      :navigateBack="() => this.$router.go(-1)"
      :nameOfRoute="'onboarding'"
    >
      <Slide :headerHeart="true" :featureHeader="true" :title="'Hilf uns, dich kennenzulernen'" :noPaddingTop="true">
        <div class="secondary-text text-section">
          so können wir Rezepte und Produkte<br>genau auf dich abstimmen
        </div>
        <div class="slide-img-area">
          <img src="../assets/img/thought-bubbles.svg" style="margin: 40px 0;" alt="">
        </div>
        <div class="secondary-text-gray text-section">
          Du kannst die Angaben<br>später jederzeit ändern.
        </div>
      </Slide>
      <Slide :title="'Lieferung'" :progress="20" :noPaddingTop="true">
        <Supermarket />
      </Slide>
      <Slide :title="'Haushalt'" :progress="40" :noPaddingTop="true">
        <Household />
      </Slide>
      <Slide title="Das essen wir nicht" :progress="60" :noPaddingTop="true">
        <Exclude />
      </Slide>
      <Slide :title="'Produktqualität'" :currentSlide="1" :progress="80" :noPaddingTop="true">
        <Bio />
      </Slide>
    </Slides>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import Slides from '@/components/slides/Slides.vue'
import Slide from '@/components/slides/Slide.vue'
import Supermarket from '@/components/settings/Supermarket.vue'
import Household from '@/components/settings/Household.vue'
import Exclude from '@/components/settings/Exclude.vue'
import Bio from '@/components/settings/Bio.vue'
import OrderDay from '@/components/settings/OrderDay.vue'
import RecipeCategories from '@/components/settings/RecipeCategories.vue'

export default {
  name: 'onboarding',
  components: {
    Page,
    Slides,
    Slide,
    Supermarket,
    Household,
    Bio,
    OrderDay,
    Exclude,
    RecipeCategories
  },
  methods: {
    // This function is executed when the user clicks the CTA button at the
    // bottom of each slide.
    clickedNext (event) {
      if (event.to === 2 && event.from === 1) {
        // If the user leaves the postal code slide without entering a postal
        // code in which REWE is available, or without entering any postal code,
        // set a zipcode from Berlin.
        this.$store.dispatch('validatePostalCodeAndStorebranch')
      } else if (event.from === 2) {
        // If the user leaves the household slide, check if the number of people
        // is not zero.
        this.$store.dispatch('checkHouseholdSize')
      }
    },
    // This function is executed at the end of the onboarding survey.
    redirect () {
      if (this.$store.state.profile && this.$store.state.profile.postal_code) {
        if (this.$store.state.onboarded) {
          this.$router.go(-1)
        } else {
          this.$store.dispatch('enterTheApp')
          this.$store.dispatch('save', { key: 'onboarded', value: true })
        }
      } else {
        this.$store.dispatch('showNotification', { message: 'Bitte gehe zurück und gib deine Postleitzahl ein, damit du die App nutzen kannst.', type: 'Error' })
      }
    },
  },
  ionViewDidEnter () {
    if (!this.$store.state.notificationsEnabled && !this.$store.state.onboarded) {
      this.$store.dispatch('askForNotificationPermission')
    }
    this.$store.dispatch('getRecipeCategories')
    this.$store.dispatch('getIngredientCategories')
    this.$store.dispatch('getBioCategories')
    this.$store.dispatch('logAnalyticsEvent', { name: 'opened_onboarding' })
  }
}
</script>
